import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class ProductionInvoicePaymentServices {
  readProductionInvoicePaymentQuery(){
      var query = `
        query ($paging:ServerPaging, $status:String) {
          GetProductionInvoicePaymentV (Paging:$paging, Status:$status) {
            production_invoice_payment {
              created_at
              last_update
              invoice_payment_id
              invoice_payment_number
              invoice_payment_type
              invoice_payment_date
              status
              producer_id
              producer_name
              payment_method
              bank
              branch
              account_number
              account_name
              round_up
              delivery_fee
              admin_fee
              other_fee
              notes
              production_invoice_payment_detail {
                created_at
                last_update
                invoice_payment_id
                invoice_id
                invoice_number
                total
                paid
                total_payment
                left
                notes
              }
            }
            total
          }
        }
      `;
      return query;
  }

  readPayableProductionInvoiceQuery(){
    var query = `
      query {
        GetProductionPayableInvoiceList {
          invoice_id
          invoice_number
          invoice_date
          production_id
          producer_id
          producer_name
          notes
          total
          paid
          total_payment
          left
        } 
      }
    `;
    return query;
  }

  async getPayableInvoiceQuery(id){
    var array = JSON.parse(id)
    
    const variables = {
      id : array
    }
    
    var query = gql`
      query ($id:[Int]) {
        GetProductionPayableInvoiceList (InvoiceIds:$id) {
          invoice_id
          invoice_number
          invoice_date
          production_id
          producer_id
          producer_name
          notes
          total
          paid
          total_payment
          left
        }
      }
    `;
    var result = await globalfunc.defaultApolloQueryDefault(query, variables);
    var convertDataToArray = this.countSisaAmount(result.data.GetProductionPayableInvoiceList);

    return convertDataToArray;
  }

  async getSinglePayableInvoiceQuery(id){
    var array = JSON.parse(id)
    
    const variables = {
      id : array
    }
    
    var query = gql`
      query ($id:[Int]) {
        GetProductionPayableInvoiceList (InvoiceIds:$id) {
          invoice_id
          invoice_number
          invoice_date
          production_id
          producer_id
          producer_name
          notes
          total
          paid
          total_payment
          left
        }
      }
    `;
    var result = await globalfunc.defaultApolloQueryDefault(query, variables);
    
    return result.data.GetProductionPayableInvoiceList[0];
  }

  async getProductionInvoicePaymentDetail(id){
    const variables = {
      id : id
    }
    var query = gql`
      query($id:Int){
        GetProductionInvoicePaymentV (InvoicePaymentId: $id) {
          production_invoice_payment {
            created_at
            last_update
            invoice_payment_id
            invoice_payment_number
            invoice_payment_type
            invoice_payment_date
            status
            producer_id
            producer_name
            payment_method
            bank
            branch
            account_number
            account_name
            round_up
            delivery_fee
            admin_fee
            other_fee
            notes
            production_invoice_payment_detail {
              created_at
              last_update
              invoice_payment_id
              invoice_id
              invoice_number
              total
              paid
              total_payment
              left
              notes
            }
          }
        }
      }
    `;
    var result = await globalfunc.defaultApolloQueryDefault(query, variables);
    return result.data.GetProductionInvoicePaymentV.production_invoice_payment[0];
  }

  async getBankQuery(){
    var query = gql`
      query {
        GetBankList{
          id
          bank_name
        }
      }
    `;
    var result = await globalfunc.defaultApolloQueryDefault(query);
    var data = [];
    for (let i = 0; i < result.data.GetBankList.length; i++) {
        var str = {
          value:result.data.GetBankList[i].bank_name, 
          label:result.data.GetBankList[i].bank_name
        }
        data.push(str);
    }

    return data;
  }

  countSisaAmount(array){
    let arrayObj = [];

    if (array != null) {
      for (let i = 0; i < array.length; i++) {
        var str = { 
          invoice_id: array[i].invoice_id,
          invoice_number: array[i].invoice_number,
          invoice_date: array[i].invoice_date,
          notes: array[i].notes,
          total: Math.round(array[i].total),
          paid: Math.round(array[i].paid),
          left: Math.round(array[i].left),
          sisa: Math.round(parseFloat(array[i].total - array[i].paid).toFixed(2)-array[i].left),
        }
        arrayObj.push(str)
      }
    }
    
    return arrayObj;
  }

  countSisaAmountEdit(array) {
    let arrayObj = [];

    if(array != null){
      for (let i = 0; i < array.length; i++) {
        var str = { 
          invoice_id: array[i].invoice_id,
          invoice_number: array[i].invoice_number,
          invoice_date: array[i].invoice_date,
          notes: array[i].notes,
          total: Math.round(array[i].total),
          paid: Math.round(array[i].paid),
          left: Math.round(array[i].total_payment),
          sisa: Math.round(parseFloat(array[i].total - array[i].paid).toFixed(2)-array[i].total_payment),
        }
        arrayObj.push(str)
      }
    }
    
    return arrayObj;
  }

  validateDuplicateSelectedData(data) {
    var array = data.split(',');

    let arrayObj = [];
    for (let i = 0; i < array.length; i++) {
      var detailArray = array[i].split('|');
      var obj = 
        {
          id: detailArray[0],
          supplier_id : detailArray[1],
        }
      arrayObj.push(obj)
    }
    
    var unique = arrayObj.map(e => e['producer_id'] ).map((e, i, final) => final.indexOf(e) === i && i).filter(obj=> arrayObj[obj]);

    if (unique.length > 1) {
      return "not match";
    }
    else {
      var id = arrayObj.map(e => parseInt(e['id']));
      return id;
    }
  }

  async addQuery(variables){
    var query = gql`
      mutation ($data:NewProductionInvoicePayment!) {
        CreateProductionInvoicePayment (NewProductionInvoicePayment:$data)
      }
    `;
      
    return await globalfunc.defaultApolloMutationDefault(query, variables)
  }

  async editQuery(variables){
    var query = gql`
      mutation ( $id:Int!, $data:NewProductionInvoicePayment! ) {
        UpdateProductionInvoicePayment ( InvPaymentId:$id, NewProductionInvoicePayment:$data )
      }
    `;
      
    return await globalfunc.defaultApolloMutationDefault(query, variables)
  }
}

export default new ProductionInvoicePaymentServices();
