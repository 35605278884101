<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>{{FormType}} Production Invoice : {{InvoiceNo}}</h2>
            </CCardHeader>
            <CCardBody>
                <div class="px-3">
                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Kode Produksi</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ProductionCode" v-model="ProductionCode" class="font-weight-bold" readonly/>
                            <label id="errorProductionCode" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Tanggal Invoice</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                :disabled="InvoiceDateDisabled"
                                v-model="InvoiceDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorInvoiceDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Status</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                            <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>

                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Jatuh Tempo Invoice</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                :disabled="InvoiceDueDateDisabled"
                                v-model="InvoiceDueDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorInvoiceDueDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold required">Tanggal Terima Invoice</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <date-picker
                                :disabled="InvoiceReceivingDateDisabled"
                                v-model="InvoiceReceivingDate"
                                format="DD MMM YYYY"
                                type="date"
                                placeholder="Select date"
                                value-type="YYYY-MM-DD"
                                style="width:100%"
                            ></date-picker>
                            <label id="errorInvoiceReceivingDate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div>

                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">PPN Tax (%)</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="PpnTax" class="font-weight-bold" :options="PpnTaxData" :value.sync="PpnTax" @change="onChangePpnTax()" />
                            <label id="errorPpnTax" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>

                    <CRow>
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Mata Uang</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CSelect id="Currency" class="font-weight-bold" :options="CurrencyData" :value.sync="Currency" />
                            <label id="errorCurrency" class="form-error" style="display: none; color: red;"></label>
                        </div>
                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Exchange Rate (%)</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <CInput id="ExchangeRate" v-model="ExchangeRate" class="font-weight-bold" type="number" @change="onChangePercentage()"/>
                            <label id="errorExchangeRate" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                        
                        <div class="col-sm-label">
                            <label class="form-label font-weight-bold">Catatan</label>
                            <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                        </div>
                        <div class="col-sm-content">
                            <textarea class="form-control" rows="2" v-model="Note"></textarea>
                            <label id="errorNote" class="form-error pb-2" style="display: none; color: red;"></label>
                        </div> 
                    </CRow>
                    
                    <CRow class="p-3">
                        <div class="p-3 border rounded" style="width: 100%;">
                            <label class="form-label font-weight-bold" style="margin-bottom: 0px"><h4>Item</h4></label>
                            <hr>

                            <datasource ref="itemDataSource" :data="this.ItemGridData" :page-size="10" :schema-model-fields="this.ItemSchemaModel"/>

                            <kendo-grid ref="gridItem"
                                        :data-source-ref="'itemDataSource'"
                                        :editable="true"
                                        :pageable="true"
                                        :filterable="true"
                                        :sortable="true"
                                        :resizable="true"
                                        :cellClose="cellClose"
                                        >

                                <kendo-grid-column  :field="'product_code'"
                                                    :title="'Kode'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'item_name'"
                                                    :title="'Nama'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'uom'"
                                                    :title="'UOM'"  
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"></kendo-grid-column>

                                <kendo-grid-column  :field="'quantity'"
                                                    :title="'Quantity'"  
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'discount'"
                                                    :title="'Diskon (%)'"
                                                    :format="'{0:N}'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'price_per_unit'"
                                                    :title="'Harga per Unit'"
                                                    :format="'{0:N0}'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'price'"
                                                    :title="'Total Harga'"
                                                    :format="'{0:N0}'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'vat_per_unit'"
                                                    :title="'Vat per unit'"
                                                    :format="'{0:N0}'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>
                                                    
                                <kendo-grid-column  :field="'vat'"
                                                    :title="'Total Vat'"
                                                    :format="'{0:N0}'"
                                                    :width="200"
                                                    :headerAttributes="{ style: 'text-align:center; font-weight:bold;' }"
                                                    :attributes="{style:'text-align:right'}"></kendo-grid-column>                                
                            </kendo-grid>
                        </div>
                    </CRow>                    
                    <br>

                    <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                    <label style="float: right;">&nbsp;&nbsp;</label>
                    <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close" @click="$router.push('/production-invoice')"> Close </CButton>
                </div>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import moment from 'moment';
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { DataSource } from '@progress/kendo-datasource-vue-wrapper';
import { response } from '../../../../infrastructure/constant/response';
import { baseUrl } from '../../../../infrastructure/constant/connection';
import ProductionInvoiceServices from '../Script/ProductionInvoiceServices.js';

export default {
    name: 'ProductionInvoiceFormPage',
    components: {
        'date-picker' : datePicker,
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
        'datasource' : DataSource,
    },
    data(){
        return{
            FormType: this.$route.params.formtype,
            View: this.$route.params.view,
            Id: this.$route.params.id,
            Error : 0,

            //Detail
            ProductionId: '',
            ProductionCode: '',
            InvoiceId: '',
            InvoiceNo: '',
            InvoiceDate: '',
            InvoiceDateDisabled: false,
            StatusData: [],
            Status: '',
            InvoiceDueDate: '',
            InvoiceDueDateDisabled: false,
            InvoiceReceivingDate: '',
            InvoiceReceivingDateDisabled: false,
            PpnTaxData: [],
            PpnTax: '',
            CurrencyData: [],
            Currency: '',
            ExchangeRate: '',
            Note: '',

            //grid
            ItemGridData: [],
            ItemSchemaModel: {
                item_id: { type: "string", editable: false },
                product_code: { type: "string", editable: false },
                item_name: { type: "string", editable: false },
                uom: { type: "string", editable: false },
                quantity: { type: "number", editable: false },
                discount: { type: "number", editable: true },
                price_per_unit: { type: "number", editable: true, validation: {required: true}},
                price: { type: "number", editable: false },
                vat_per_unit: { type: "number", editable: false },
                vat: { type: "number", editable: false },
            },
        }   
    },
    async mounted () {
        if (this.FormType == 'Add') {
            var data = await ProductionInvoiceServices.getProductionInvoiceDetailData(this.Id);
            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            
            this.ProductionId = this.Id;
            this.ProductionCode = data.production_code;
            this.InvoiceDate = dateNow;

            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';

            this.InvoiceDueDate = dateNow;
            this.InvoiceReceivingDate = '';

            this.PpnTaxData = await ProductionInvoiceServices.getDropdownPPNTax();
            var ppnTax = await globalfunc.getPPNTax();
            this.PpnTax = ppnTax.label;

            this.CurrencyData = await ProductionInvoiceServices.getCurrencyDropdown();
            this.Currency = 1;

            this.ExchangeRate = 0;
            this.Note = '';

            this.ItemGridData = ProductionInvoiceServices.materialGridDataBuilder(data, "Add");
            this.SaveType = 'Add';
        } 
        else {        
            var data = await ProductionInvoiceServices.getProductionInvoiceDetailQuery(this.Id);
            
            this.InvoiceId = this.Id;
            this.ProductionId = data.production_id;
            this.ProductionCode = data.production_code;

            this.InvoiceDate = data.invoice_date;

            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_production_invoice');
            this.Status = data.status;

            this.InvoiceDueDate = data.invoice_due_date;
            this.InvoiceReceivingDate = data.invoice_received_date;
            
            this.PpnTaxData = await ProductionInvoiceServices.getDropdownPPNTax();
            var ppnTax = await globalfunc.getPPNTax();
            this.PpnTax = data.ppn_tax_percentage;

            this.CurrencyData = await ProductionInvoiceServices.getCurrencyDropdown();
            this.Currency = data.currency_id;

            this.ExchangeRate = data.exchange_rate;
            this.Note = data.notes;

            this.ItemGridData = ProductionInvoiceServices.materialGridDataBuilder(data.production_invoice_detail, "Edit");
            
            this.SaveType = 'Edit';

            if(this.View == 'true'){
                $(".save-button").hide();
            }
        }
    },    
    methods: {
        onChangePercentage(){
            this.ExchangeRate = this.$globalfunc.percentValidate(this.ExchangeRate);
        },
        cellClose(e) {
            var grid = this.$refs.gridItem.kendoWidget();
            var dataItem = e.sender.dataItem($(e.container).parent());

            dataItem.discount = this.$globalfunc.percentValidate(dataItem.discount);
            
            dataItem.price = dataItem.price_per_unit * dataItem.quantity;
            var disc = dataItem.price_per_unit * (dataItem.discount / 100);
            var ppn = dataItem.price_per_unit * (this.PpnTax / 100);
            dataItem.vat_per_unit = dataItem.price_per_unit - disc + ppn;
            dataItem.vat = dataItem.vat_per_unit * dataItem.quantity;

            this.ItemGridData = globalfunc.objectToArrayConverter(grid.dataSource._data, 'ProductionInvoice-DetaiFormGrid');
        },
        onChangePpnTax() {
            var grid = this.$refs.gridItem.kendoWidget().dataSource._data;
            this.ItemGridData = ProductionInvoiceServices.materialGridDataBuilder(grid, "Tax", this.PpnTax)
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            //item GridData
            var gridItem = this.$refs.gridItem.kendoWidget().dataSource._data;

            if (this.InvoiceDate == '' || this.InvoiceDate == null) {
                this.errorShow('errorInvoiceDate');
            }
            if (this.InvoiceDueDate == '' || this.InvoiceDueDate == null) {
                this.errorShow('errorInvoiceDueDate');
            }            
            if (this.InvoiceReceivingDate == '' || this.InvoiceReceivingDate == null) {
                this.errorShow('errorInvoiceReceivingDate');
            }
            if (gridItem.length == 0) {
                this.$swal("Error", "Data Material tidak boleh kosong", "error");
                this.Error++;
            }

            //Validation Qty_DO > Qty_PO
            // for (let i = 0; i < gridItem.length; i++) {
            //     if (gridItem[i].quantity == 0) {
            //         this.Error++;
            //         this.$swal("Error", "Delivery QTY tidak boleh 0", "error");
            //     }
            //     if (gridItem[i].quantity > gridItem[i].remaining_quantity) {
            //         this.Error++;
            //         this.$swal("Error", "Delivery QTY tidak boleh lebih besar dari Remainig QTY ", "error");
            //     }
            // }
        },
        async saveClick(){
            this.inputValidation();

            //Proses
            if(this.Error == 0){                
                var gridItem = this.$refs.gridItem.kendoWidget().dataSource._data;
                var detailArray = globalfunc.objectToArrayConverter(gridItem, "ProductionInvoice-ItemDetail");
                
                const productionInvData = {
                    status: this.Status,
                    production_id: this.ProductionId,
                    invoice_date: this.InvoiceDate,
                    invoice_due_date: this.InvoiceDueDate,
                    invoice_received_date: this.InvoiceReceivingDate,
                    ppn_tax_percentage: this.PpnTax,
                    currency_id: this.Currency,
                    exchange_rate: this.ExchangeRate,
                    notes: this.Note,
                    production_invoice_detail: detailArray
                }

                if (this.SaveType == 'Add') {
                    this.$loading(true);

                    const variables = {
                        data : productionInvData
                    }                    

                    ProductionInvoiceServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success").then(
                            this.$router.push({ path: '/production-invoice' })
                        );
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                } 
                else if (this.SaveType == 'Edit') {
                    this.$loading(true);

                    const variables = {
                        id: parseInt(this.InvoiceId),
                        data : productionInvData
                    }
                    
                    ProductionInvoiceServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success")
                            .then(res => {
                                if(res.isConfirmed == true || res.isDismissed == true){
                                    window.location = baseUrl + '/production-invoice'
                                }
                            });                  
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    } 
}
</script>

<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>