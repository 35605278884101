<template>
    <div>
        <div class="modal fade" id="ProductionInvoiceCreateModal" tabindex="-1" aria-labelledby="ProductionInvoiceCreateModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl-custom modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="ProductionInvoiceCreateModalLabel" class="font-weight-bold">Add Production Invoice</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <kendo-grid ref="grid"
                            :data-source="dataSource"
                            :pageable="pageableConfig"
                            :filterable="filterableConfig"
                            :sortable="true"
                            :columns="columns"
                            :resizable="true"
                        >
                        </kendo-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection'
import ProductionInvoiceServices from '../Script/ProductionInvoiceServices.js';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'DeliveryOrderCreateForm',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    // props: ['createDetailClick'],
    mounted:  function () {
        var createDetailClick = this.createDetailClick;
        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;

        GridElement.on("click", "#CreateDetailButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            createDetailClick(dataItem.production_id);
        })
    },
    data: function () {
        return {
            dataSource: [],
            columns: [
                { title: "Action", width: 100, headerAttributes:{ style: "text-align:center; vertical-align:middle; font-weight:bold;" }, attributes: { "class": "k-text-center" },
                    template: '<div class="btn-group"> <button type="button" class="btn btn-success btn-md rounded" id="CreateDetailButton"> Create </button> </div>'
                },
                { field: "status", width: 200,title: "Status", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} }, 
                { field: "production_type", width: 200, title: "Tipe Produksi", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: '#= production_type == 1 ? "Internal" : "External" #' },
                { field: "production_code", width: 200, title: "Kode Produksi", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "production_result_product_code", width: 200,title: "Kode Produk", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "production_result_item_name", width: 200,title: "Nama Produk", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "production_result_quantity", width: 200, title: "Quantity", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "machine_name", width: 200,title: "Mesin", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },  
                { field: "storage_id", width: 200, title: "Gudang", headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "waste_percentage", width: 200, title: "Persentasi Waste", headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:right;"} },
                { field: "producer_name", width: 200, title: "Nama Producer", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: '#= production_type == 1 ? "Internal" : producer_name #' },
                { field: "created_at", width: 200, title: "Tanggal Dibuat", headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #", attributes:{style: "text-align:center;"} }
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    methods: {
        addClick(){
            this.dataSource = new kendo.data.DataSource ({
                page: 1,
                pageSize: 10,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { 
                                query: ProductionInvoiceServices.getProductionInvoiceAbleQuery(),
                                variables: variables
                            };
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if (response.data.GetProduction == null) {
                            return [];
                        }
                        else {
                            return response.data.GetProduction.ProductionResponse;
                        }
                    },
                    total: function (response) {
                        if (response.data.GetProduction == null) {
                            return 0;
                        }
                        else {
                            return response.data.GetProduction.Total;
                        }
                    },
                }
            });
            
            window.$('#ProductionInvoiceCreateModal').modal('show');
        },
        createDetailClick(data){
            window.$('#ProductionInvoiceCreateModal').modal('hide');
            this.$router.push({ name: 'Production Invoice Form', params: {  formtype:'Add', id:data, view:'new' } })
        },
    }
}
</script>
<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>