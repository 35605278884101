<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Production Invoice Payment</h2>
            </CCardHeader>
                <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>
                
                <production-invoice-payment-grid ref="grid" :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" :statusClick="statusClick" />
                <production-invoice-payment-create-form  ref="ProductionInvoicePaymentCreateForm" :reload="reload"/>
                <production-invoice-payment-status-form ref="ProductionInvoicePaymentStatusForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { globalfunc } from '../../../shared/GlobalFunction';
import { response } from '../../../infrastructure/constant/response';
import ProductionInvoicePaymentServices from './Script/ProductionInvoicePaymentServices.js';
import ProductionInvoicePaymentGrid from './Grid/ProductionInvoicePaymentGrid.vue';
import ProductionInvoicePaymentCreateForm from './Component/ProductionInvoicePaymentCreateForm.vue';
import ProductionInvoicePaymentStatusForm from './Component/ProductionInvoicePaymentStatusForm.vue';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'ProductionInvoicePayment',
    components: {
        'production-invoice-payment-grid': ProductionInvoicePaymentGrid,
        'production-invoice-payment-create-form': ProductionInvoicePaymentCreateForm,
        'production-invoice-payment-status-form': ProductionInvoicePaymentStatusForm,
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Production Invoice Payment');
        document.getElementById("add").disabled = permission.cperms ? false : true;
        
        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$refs.ProductionInvoicePaymentCreateForm.addClick();
        },
        editClick(id, view){
            // this.$router.push({ name: 'Production Invoice Payment Form', params: { formtype:'Edit', id:id, view:view } });
            const routeData = this.$router.resolve({ name: 'Production Invoice Payment Form', params: { formtype:'Edit', id:id, view:view } });
            window.open(routeData.href, '_blank');
        },
        async deleteClick(id){
            var data = await ProductionInvoicePaymentServices.getProductionInvoicePaymentDetail(id);

            //item GridData
            var gridItem = data.production_invoice_payment_detail;
            var ProductionInvoicePaymentDetail = this.$globalfunc.objectToArrayConverter(gridItem, "ProductionInvoicePayment-Detail");

            const ProductionInvoicePaymentData = {
                invoice_payment_type: data.invoice_payment_type,
                invoice_payment_date: data.invoice_payment_date,
                status: "Delete",
                producer_id: data.producer_id,
                payment_method: data.payment_method,
                bank: data.bank,
                branch: data.branch,
                account_number: data.account_number,
                account_name: data.account_name,
                notes: data.notes,
                round_up: parseInt(data.round_up),
                delivery_fee: parseInt(data.delivery_fee),
                admin_fee: parseInt(data.admin_fee),
                other_fee: parseInt(data.other_fee),
                production_invoice_payment_detail: ProductionInvoicePaymentDetail
            }

            const variables = {
                id : parseInt(id),
                data : ProductionInvoicePaymentData
            }

            this.$swal(sweet_alert.delete).then((result) => {
                if (result.isConfirmed == true) {
                    ProductionInvoicePaymentServices.editQuery(variables).then(res => {
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");                 
                    }).catch(error => {
                        var errorMessage = this.$globalfunc.ErrorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        statusClick(data) {
            this.$refs.ProductionInvoicePaymentStatusForm.statusClick(data);
        },
        reload(){
            this.gridReload++;
            this.statusFilter();
        },
        async statusFilter() {
            // var countStatus = await globalfunc.countTotal(window.location.pathname);
            // this.statusButton = await this.$globalfunc.dynamicStatusButton('ddl_status_production_invoice_payment', countStatus);
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
                $("#btnUnclear").on('click', function() {
                    vue.$refs.grid.changeStatus('Unclear');
                });
                $("#btnClear").on('click', function() {
                    vue.$refs.grid.changeStatus('Clear');
                });
                $("#btnDelete").on('click', function() {
                    vue.$refs.grid.changeStatus('Delete');
                });
            })
        },
    }
}
</script>

<style scoped>
</style>